<template>
  <section id="skills" v-if="skillsCollection && skillsCollection.isAllowed">
    <PageTitle :title="skillsCollection.title" :icon="skillsCollection.icon"/>
    <v-container>
      <div>
        <h3 class="title text-center">LANGUAGES</h3>
        <ul>
          <li v-for="(language, index) in skillsCollection.languages" :key="index">
            <v-img max-width="400px" :src="language.url"/>
            <span class="text-uppercase">{{language.name}}</span>
          </li>
        </ul>
      </div>
      <div>
        <h3 class="title text-center mt-10">FRAMEWORKS</h3>
        <ul>
          <li v-for="(framework, index) in skillsCollection.frameworks" :key="index">
            <v-img class="skills-img" :src="framework.url"/>
            <span class="text-uppercase">{{framework.name}}</span>
          </li>
        </ul>
      </div>
      <div>
        <h3 class="title text-center mt-10">DATABASES</h3>
        <ul>
          <li v-for="(framework, index) in skillsCollection.databases" :key="index">
            <v-img class="skills-img" :src="framework.url"/>
            <span class="text-uppercase">{{framework.name}}</span>
          </li>
        </ul>
      </div>
      <div>
        <h3 class="title text-center mt-10">HOSTING</h3>
        <ul>
          <li v-for="(framework, index) in skillsCollection.hosting" :key="index">
            <v-img class="skills-img" :src="framework.url"/>
            <span class="text-uppercase">{{framework.name}}</span>
          </li>
        </ul>
      </div>
      <div>
        <h3 class="title text-center mt-10">ADDITIONAL</h3>
        <ul>
          <li v-for="(additional, index) in skillsCollection.additional" :key="index">
            <v-img class="skills-img" :src="additional.url"/>
            <span class="text-uppercase">{{additional.name}}</span>
          </li>
        </ul>
      </div>
    </v-container>
  </section>
</template>

<script>
import firebaseGetCollection from '@/mixins/firebase8/collection/get';
import PageTitle from '@/components/shared/PageTitle.vue';

export default {
  name: 'Skills',
  components: {
    PageTitle,
  },

  data() {
    return {
      skillsCollectionRaw: null,
    };
  },

  computed: {
    skillsCollection() {
      return (this.skillsCollectionRaw && this.skillsCollectionRaw.results)
        ? this.skillsCollectionRaw.results[0]
        : null;
    },
  },

  async created() {
    await this.subToCollection();
  },

  destroyed() {
    this.unSubToCollection();
  },

  methods: {
    async subToCollection() {
      this.skillsCollectionRaw = await firebaseGetCollection('skills');
    },

    unSubToCollection() {
      this.skillsCollectionRaw = null;
    },
  },
};
</script>

<style lang="scss" scoped>
  ul {
    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    text-align: center;
    width: 5rem;
    margin: 1rem auto;
  }
</style>
